<script>
import { SATELLITES_DATA_ADVANCED_INDICATORS } from "@/graphql/queries"
import darkColors from '@/utils/data/darkColors.json'
import { satellitesDataType } from '@/utils/enums';
import ptBR from 'apexcharts/dist/locales/pt-br.json'
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    apexcharts: () => import('vue-apexcharts')
  },

  data() {
    return {
      loading: false,
      maxY: 6,
      checkboxCamp: false,
      checkboxOptic: true,
      checkboxRadar: false,
      chartOptions: {
        chart: {
          id: 'chart-one',
          type: 'line',
          defaultLocale: "pt-br",
          locales: [ptBR],
          zoom: {
            enabled: false
          },
        },

        dataLabels: {
          enabled: false
        },

        stroke: {
          curve: 'smooth', // smooth
          width: 3
        },

        legend: {
          showForSingleSeries: true,
          offsetY: 48,
          position: 'right',
          verticalAlign: 'center'
        },

        markers: {
          size: 5,
        },

        title: {
          text: 'Matéria seca disponível',
          align: 'center'
        },

        subtitle: {
          text: '(Kg/piquete)',
          align: 'center'
        },

        colors: [],

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },

        yaxis: {
          min: 0,
          max: () => this.maxY,

          labels: {
            formatter: function (val) {
              if (typeof val === 'number') return val.toLocaleString(undefined, {useGrouping: true, maximumFractionDigits: 0});
              return val;
            },
            // formatter: function (val, index) {
            //   if (index === 0) return (val).toFixed(0).toLocaleString();
            //   return val;
            // },
          },
        },

        xaxis: {
          type: 'datetime',

          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM/yy",
              day: 'dd MMM',
              hour: 'HH:mm',
            }
          }
        },
      },

      series: []
    }
  },

  computed: {
    ...mapState('dashboard', ['filter', 'paddocks']),
    ...mapGetters('auth', ['authStatus', 'user'])
  },

  watch:{
    filter() {
      this.fetch()
    }
  },

  methods: {
    async fetch() {
      this.loading = true
      try{
        const filter = this.filter

        if (!filter.farmId || !filter.startAt || !filter.endAt){ 
          this.loading = false
          return
        }
        let where = {
        }
        let filtersOptions = []
        let paddock = {}
        where = {
          paddock,
            _or: [
              {
                _and: {
                  type: { _in: filtersOptions },
                  info_lost: { _lte: 80 }
                }
              }
            ]
          }
        if (this.checkboxRadar) {
          filtersOptions.push("RADAR")
        }
        if (this.checkboxOptic) {
          filtersOptions.push("OPTICO")
        }
        if (this.checkboxCamp) {
          filtersOptions.push("CAMPO")
        }
          where.farm_id = { _eq: filter.farmId }
          where.captured_at = { _gte: filter.startAt, _lte: filter.endAt }
          if(filter.moduleId) where.paddock.ref_module_id = {_eq: filter.moduleId}
          // if(filter.moduleId) where.module_id = { _eq: filter.moduleId }
          if(filter.paddockIds && filter.paddockIds.length > 0) where.paddock_id = { _in: filter.paddockIds }
          const { data } = await this.$apollo.query({ query: SATELLITES_DATA_ADVANCED_INDICATORS, variables: { where } })
          this.chartOptions = {}
          this.series = []
          if (data.satellites_data_advanced.length > 0 && this.paddocks.length > 0) {
            this.mountChart(data.satellites_data_advanced)
          } else  if(filter.moduleId) {
            where.paddock = {module_id: {_eq: filter.moduleId}}
            const { data } = await this.$apollo.query({ query: SATELLITES_DATA_ADVANCED_INDICATORS, variables: { where } })
            if (data.satellites_data_advanced.length > 0 && this.paddocks.length > 0) {
              this.mountChart(data.satellites_data_advanced)
            } 
          }
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },

    mountChart(val) {
      const chartOptions = cloneDeep(this.chartOptions)
      const paddocks = sortBy(uniqBy(val.map(item => item.paddock), (item) => item.id), ['name'])
      const series = paddocks.map(item => {
        const indexA = this.paddocks.findIndex(itemIndex => itemIndex.id === item.id)
        const color = darkColors.colors[indexA]? darkColors.colors[indexA] : ''
        return {
          id: item.id,
          color,
          name: item.name,
          data: val
            .filter(itemVal => itemVal.paddock.id === item.id)
            // .map(itemVal => [itemVal.captured_at, itemVal.ms_adjusted])
            .map(itemVal => ({
              x: itemVal.captured_at,
              y: itemVal.ms_adjusted,
              goals: [
                { name: 'Fonte', value: satellitesDataType[itemVal.type] },
                { name: 'EM (kcal/kg)', value: itemVal.em ? itemVal.em.toLocaleString(undefined, {useGrouping: true, maximumFractionDigits: 0}) : 0 },
                { name: 'PB', value: (itemVal.pb ? itemVal.pb.toLocaleString(undefined, {maximumFractionDigits: 0}) : 0) + '%' },
                { name: 'FDN', value: (itemVal.fdn ? itemVal.fdn.toLocaleString(undefined, {maximumFractionDigits: 0}) : 0) + '%' },
              ]
            }))
        }
      })
      chartOptions.colors = series.map(item => item.color)
      // Date and types
      const dateTypes = val.reduce((acc, item) => {
          if (!acc[item.captured_at]) {
              acc[item.captured_at] = []
          }
          if (acc[item.captured_at].includes('1' + item.type[0]) || acc[item.captured_at].includes('2' + item.type[0]) || acc[item.captured_at].includes(item.type[0])) return acc
          if (item.type[0] === 'O') acc[item.captured_at].push(('1' + item.type[0]))
          if (item.type[0] === 'R') acc[item.captured_at].push(('2' + item.type[0]))
          if (item.type[0] === 'C') acc[item.captured_at].push((item.type[0]))
          return acc
        }, {})

      // annotations
      const annotations = Object.entries(dateTypes).map(item => ({
            x: new Date(item[0]).getTime(),
            borderColor: "#8d8d8d",
            label: {
              borderColor: "#8d8d8d",
              orientation: 'horizontal',
              text: item[1].join('-'),
              style: {
                color: "#fff",
                background: "#8d8d8d"
              },
            }
          }))

      chartOptions.annotations = {
        xaxis: annotations
      }

      // chartOptions.annotations = {
      //   points: val
      //     .filter(item => ['AMOSTRAGEM', 'RADAR'].indexOf(item.type) > -1)
      //     .map(item => ({
      //       x: new Date(item.captured_at).getTime(),
      //       // x: item.captured_at,
      //       y: item.ms_adjusted,
      //       marker: {
      //         size: 0,
      //       },
      //       image: {
      //         path:  item.type === 'RADAR' ? 'https://www.svgrepo.com/show/77418/satellite.svg' : 'https://www.svgrepo.com/show/173097/flask-with-liquid.svg',
      //         width: 16,
      //         height: 16,
      //       }
      //     }))
      //   }
      // console.log({chartOptions})
      this.chartOptions = chartOptions
      this.series = series
      this.maxY = val.reduce((max, item) => max > item.ms_adjusted ? max : item.ms_adjusted, 0) * 1.05
    },

    legendClickHandler(chartContext, seriesIndex){
      let paddockId = this.series[seriesIndex].id
      if (this.filter.paddockIds && this.filter.paddockIds.length === 1) {
        this.filter.paddockIds = []
      } else {
        this.filter.paddockIds = [paddockId]
      }
      this.$store.dispatch('dashboard/changeFilter', this.filter)
    }
  }
}
</script>

<template>
  <v-card :loading="loading" class="chatOne">
    <v-card-text>
      <v-row>
        <v-checkbox v-model="checkboxOptic" label="Satélite" @change="fetch()"/>
        <v-checkbox v-if="user.role !== 'customer'" v-model="checkboxRadar" label="2R " @change="fetch()"/>
        <v-checkbox v-model="checkboxCamp" label="Campo " @change="fetch()"/>
      </v-row>
      <v-row>
        <v-col class="overflow-x-auto">
          <apexcharts height="500" class="overflow-chart" type='line' :options="chartOptions" :series="series" @legendClick="legendClickHandler"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.apexchartschartxone {
  .apexcharts-tooltip-text-goals-label {
    .apexcharts-tooltip-marker {
      display: none;
    }
  }
}
.overflow-chart {
  min-width: 1000px;
}

.apexcharts-canvas { 
  background-image: url("../../../../../functions/images/mark-small-opacity20.png") !important;
  background-position: center;
  background-repeat:no-repeat;
}
</style>
